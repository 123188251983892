const stores = {
    state () {
        return {
            userData:{}
        }
    },
    mutations: {
        setUserinfo (state,data) {
            state.userData = {}
            state.userData = data.userData
            localStorage.setItem('userInfo',JSON.stringify(state.userData))
        },
        userLogOut(state){
            console.log('userLogOut')
            state.userData = {}
            localStorage.clear()
        }
    }
}
export default stores