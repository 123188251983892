const routes = [
    {
        path:'/',
        name:'welcomeView',
        component: () => import(/* webpackChunkName: "about" */ '../views/public/welcomeView.vue')
    },
    {
        path:'/his',
        name:'his',
        children:[
            {
                path:'login',
                name:'his-login',
                component: () => import(/* webpackChunkName: "about" */ '../views/his/login.vue')
            },
        ]
    },
    {
        path:'/login',
        name:'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/login/loginView.vue')
    },
    {
        path:'/register',
        name:'register',
        component: () => import(/* webpackChunkName: "about" */ '../views/register/registerView.vue')
    },
    {
        path:'/register',
        name:'register',
        component: () => import(/* webpackChunkName: "about" */ '../views/register/registerView.vue')
    },
    {
        path:'/registerComp',
        name:'registerComp',
        component: () => import(/* webpackChunkName: "about" */ '../views/register/registerCompView.vue')
    },
    {
        path:'/fgpwd',
        name:'fgpwd',
        component: () => import(/* webpackChunkName: "about" */ '../views/fgpwd/fgpwdView.vue')
    },
    {
        path:'/registerInvite',
        name:'registerInvite',
        component: () => import(/* webpackChunkName: "about" */ '../views/register/registerInviteView.vue')
    },
    {
        path:'/admin',
        name:'adminLoginView',
        component: () => import(/* webpackChunkName: "about" */ '../views/login/adminloginView.vue')
    },
]
export default routes